import { useContent, usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { AspectRatio, Box, Card, Container, Img } from "@xcorejs/ui";
import RobeCarousel from "design-system/robe/Carousel";
import { Product, ProductDetailPage } from "xcore/types";

import { useGallery } from "./data";
import useProductDetailPageStore from "./useProductDetailPageStore";
import { PropsWithChildren, useMemo, useRef, useState } from "react";
import DownloadButton from "../../../components/DownloadButton";
import { Skeleton } from "@mui/joy";

export const RobeLightBoxButton = ({ href, children }: PropsWithChildren<{ href: string }>) => {
  return (
    <DownloadButton href={href} ml={{ _: 2 }} download>
      {children}
    </DownloadButton>
  );
};

const ProductPictures = () => {
  const [{ values }, { stringify, file, value }] = usePageContent<Product>();
  const [productDetail] = useContent<[ProductDetailPage]>();
  const { setShowSubheader } = useProductDetailPageStore();
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  const imgRefs = useRef<{ [key: number]: HTMLImageElement | null }>({});

  const handleLightboxClose = () => {
    setShowSubheader(true);
    document.body.classList.remove("no-scroll");
  };

  const imagesPngs = useMemo(() =>
    values.images.map((i) => file(i, { width: 1920, height: 1920, withoutEnlargement: true, format: "png" })),
  [file, values.images]);

  const imagesJpegs = useMemo(() =>
    values.images.map((i) => file(i, { width: 1920, height: 1920, withoutEnlargement: true, format: "jpeg" })),
  [file, values.images]);

  const [openLightbox] = useGallery({
    images: imagesJpegs,
    createButtons: (offset: number) => {
      return [
        <RobeLightBoxButton
          key="png"
          href={imagesPngs[offset]}
        >
          {stringify(productDetail.values.downloadOriginalImageButtonText)}
        </RobeLightBoxButton>,
        <RobeLightBoxButton
          key="jpg"
          href={imagesJpegs[offset]}
        >
          {stringify(productDetail.values.downloadJPGButtonText)}
        </RobeLightBoxButton>
      ];
    },
    onClose: handleLightboxClose,
    fitToPageContent: true,
    disableScroll: true,
    hideProductSubHeader: true
  });

  return (
    <Box id={"#" + stringify(productDetail.values.anchorProductPictures)}>
      <Box
        id={stringify(productDetail.values.anchorProductPictures)}
        transform={{ _: "translateY(-6rem)", md: "translateY(-14rem)" }}
      />
      <Container
        flexDirection="column"
        py={{ _: "3rem", sm: "5rem" }}
      >
        {values.images.length > 1 && (
          <RobeCarousel
            mt={{ _: "3rem", sm: "6rem" }}
            mx={{ _: "1.7rem", md: "-1.5rem" }}
            maxWidth={{ _: "calc(100% - 3.4rem)", md: "100%" }}
            dots={false}
            bg="transparent"
            slides={value(values).images.map((img, i) => {
              const isImageInLoadedImagesArray = loadedImages.some(loadedImg => loadedImg === img.id);

              return (
                <Box
                  key={i}
                  px={{ _: "0.5rem", md: "1.5rem" }}
                  bg="#fff"
                  width="100%"
                  onClick={() => openLightbox(i)}
                >
                  {!isImageInLoadedImagesArray && <Skeleton variant="rectangular" width="100%" height="100%" />} {/* Skeleton loading must be separately. If image would be the child of Skeleton, it would fetch the image twice */}

                  <Card
                    variant="outline"
                    width="100%"
                    maxWidth="100%"
                    body={(
                      <Box width="100%" cursor="pointer">
                        <AspectRatio ratio={1} width="100%">
                          <img
                            src={file(img, { width: 242, height: 260 })}
                            srcSet={`${file(img, { width: 242, height: 260 })} 1x, ${file(img, { width: 242, height: 260, enlargement: 2 })} 2x `}
                            alt={stringify(values.name)}
                            style={{ maxWidth: "80%", maxHeight: "100%" }}
                            loading="eager" // Do not set lazy loading, it would cause infinite Skeleton loading in Firefox
                            onLoad={() => {
                              if (!isImageInLoadedImagesArray) {
                                setLoadedImages(prevImgs => [...prevImgs, img.id]);
                              }
                            }}
                            ref={(el) => {
                              // Only set the ref once per image
                              if (el && !imgRefs.current[img.id]) {
                                imgRefs.current[img.id] = el;

                                // If the image is already loaded from cache then update state immediately.
                                if (el.complete) {
                                  setLoadedImages((prev) => {
                                    // Avoid duplicates.
                                    if (!prev.includes(img.id)) {
                                      return [...prev, img.id];
                                    }
                                    return prev;
                                  });
                                }
                              }
                            }}
                          />
                        </AspectRatio>
                      </Box>
                    )}
                    _body={{
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      justifyContent: "space-between"
                    }}
                    _hover={{
                      border: "2px solid rgba(69, 86, 99, 0.75)"
                    }}
                    transition="border 300ms"
                  />
                </Box>
              );
            })}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 768 },
                items: 4
              },
              sm: {
                breakpoint: { max: 767, min: 480 },
                items: 2
              },
              xs: {
                breakpoint: { max: 479, min: 0 },
                items: 2
              }
            }}
            arrows
          />
        )}
      </Container>
    </Box>
  );
};

export default ProductPictures;
